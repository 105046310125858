body {
  font-family: "Oxygen", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("assets/graphics/background.jpg") !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.ui.header {
  color: #5b8359 !important;
  font-family: "Archivo Narrow", sans-serif;
}

h1 {
  font-size: 35px !important;
}
