#topbar {
  min-width: 800px;
  max-width: 1000px;
  height: 100px;
  text-align: left;
  margin: 1% auto 0 auto;
  background: #ada url("../../../assets/graphics/mainPanel.png");
  color: #fff;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #777;
}
#topbar #header {
  text-align: center;
  color: #fff;
  background: url("../../../assets/graphics/logo.png") left center no-repeat;
  background-position: 18px 14px;
  height: 100px;
}
#topbar a:hover {
  text-decoration: none;
}
#topbar #header h1 {
  font-family: "Archivo Narrow", sans-serif im !important;
  margin: 0;
  color: #fff;
  padding: 10px 0 0 25px;
  font-size: 50px;
  letter-spacing: 40px;
  text-align: center;
  text-shadow: 0px 1px 5px #333;
}
#topbar #header span {
  font-family: "Archivo Narrow", sans-serif;
  font-weight: 300;
  letter-spacing: 5px;
  text-shadow: 1px 0px 2px #333;
  padding-top: 2px;
}
#topbar img {
  padding: 5px 0 0 15px;
}
